<template>
  <div>
    <div v-show="!displayDashboard" class="text-center">
      <div class="spinner-border text-secondary " role="status"></div>
      <h5 class="mb-0 mt-2">Initializing dashboard</h5>
      <span class="form-text text-muted">
        Please wait while system retrieves all data from database.
      </span>
    </div>

    <div id="records-dashboard" v-show="displayDashboard">
      <div>
        <LineChart></LineChart>
      </div>

      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="card rounded shadow-sm">
                <div class="card-body">
                  <div class="card-title">
                    <strong>CAV Daily Statistics</strong>
                  </div>
                  <div class="form-group">
                    <label for="">Date</label>
                    <input
                      type="date"
                      v-model="date"
                      class="form-control col-auto"
                      @change="getCavDailyAccomplishments"
                    />
                  </div>

                  <table class="table table-bordered mt-3">
                    <tbody>
                      <tr>
                        <td>Confirmed payments</td>
                        <th>
                          {{ cavDailyAccomplishments.num_payments_confirmed }}
                        </th>
                      </tr>
                      <tr>
                        <td>No. of CAV processed</td>
                        <th>
                          {{ cavDailyAccomplishments.num_cav_generated }}
                        </th>
                      </tr>
                      <tr>
                        <td>For release</td>
                        <th>
                          {{ cavDailyAccomplishments.for_release }}
                        </th>
                      </tr>
                      <tr>
                        <td>Released</td>
                        <th>
                          {{
                            cavDailyAccomplishments.num_applications_released
                          }}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card rounded shadow-sm">
                <div class="card-body">
                  <div v-if="pillLoader" class="d-flex justify-content-center">
                    <div
                      class="spinner-border text-secondary"
                      role="status"
                    ></div>
                  </div>
                  <div v-else>
                    <div class="progress-group">
                      <div class="progress-group-header">
                        <div>
                          Released - <b>{{ statuses[6].total }}</b>
                        </div>
                        <div class="mfs-auto font-weight-bold">
                          {{ statuses[6].percentage }}
                        </div>
                      </div>
                      <div class="progress-group-bars">
                        <div class="progress progress-xs">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            :style="{
                              width: statuses[6].percentage,
                              backgroundColor: '#2eb85c',
                            }"
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div class="progress-group">
                      <div class="progress-group-header">
                        <div>
                          For Document Validation -
                          <b>{{ statuses[1].total }}</b>
                        </div>
                        <div class="mfs-auto font-weight-bold">
                          {{ statuses[1].percentage }}
                        </div>
                      </div>
                      <div class="progress-group-bars">
                        <div class="progress progress-xs">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            :style="{
                              width: statuses[1].percentage,
                              backgroundColor: '#321fdb',
                            }"
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div class="progress-group">
                      <div class="progress-group-header">
                        <div>
                          For Payment Validation -
                          <b>{{ statuses[2].total }}</b>
                        </div>
                        <div class="mfs-auto font-weight-bold">
                          {{ statuses[2].percentage }}
                        </div>
                      </div>
                      <div class="progress-group-bars">
                        <div class="progress progress-xs">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            :style="{
                              width: statuses[2].percentage,
                              backgroundColor: '#321fdb',
                            }"
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div class="progress-group">
                      <div class="progress-group-header">
                        <div>
                          Pending Payment - <b>{{ statuses[8].total }}</b>
                        </div>
                        <div class="mfs-auto font-weight-bold">
                          {{ statuses[8].percentage }}
                        </div>
                      </div>
                      <div class="progress-group-bars">
                        <div class="progress progress-xs">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            :style="{
                              width: statuses[8].percentage,
                              backgroundColor: '#e55353',
                            }"
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div class="progress-group">
                      <div class="progress-group-header">
                        <div>
                          For Records Verification -
                          <b>{{ statuses[3].total }}</b>
                        </div>
                        <div class="mfs-auto font-weight-bold">
                          {{ statuses[3].percentage }}
                        </div>
                      </div>
                      <div class="progress-group-bars">
                        <div class="progress progress-xs">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            :style="{
                              width: statuses[3].percentage,
                              backgroundColor: '#321fdb',
                            }"
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div class="progress-group">
                      <div class="progress-group-header">
                        <div>
                          For Process - <b>{{ statuses[4].total }}</b>
                        </div>
                        <div class="mfs-auto font-weight-bold">
                          {{ statuses[4].percentage }}
                        </div>
                      </div>
                      <div class="progress-group-bars">
                        <div class="progress progress-xs">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            :style="{
                              width: statuses[4].percentage,
                              backgroundColor: '#321fdb',
                            }"
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div class="progress-group">
                      <div class="progress-group-header">
                        <div>
                          For Release- <b>{{ statuses[5].total }}</b>
                        </div>
                        <div class="mfs-auto font-weight-bold">
                          {{ statuses[5].percentage }}
                        </div>
                      </div>
                      <div class="progress-group-bars">
                        <div class="progress progress-xs">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            :style="{
                              width: statuses[5].percentage,
                              backgroundColor: '#321fdb',
                            }"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress-group">
                      <div class="progress-group-header">
                        <div>
                          Pending - <b>{{ statuses[7].total }}</b>
                        </div>
                        <div class="mfs-auto font-weight-bold">
                          {{ statuses[7].percentage }}
                        </div>
                      </div>
                      <div class="progress-group-bars">
                        <div class="progress progress-xs">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            :style="{
                              width: statuses[7].percentage,
                              backgroundColor: '#e55353',
                            }"
                          ></div>
                        </div>
                      </div>
                    </div>

                    <span
                      >Total CAV Applications:
                      <b>{{ statuses['total_apps'] }}</b></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card rounded shadow-sm">
                <div class="card-body">
                  <div class="card-title">
                    <strong> Purpose of application</strong>
                  </div>
                  <div
                    v-if="pieChartLoader"
                    class="d-flex justify-content-center"
                  >
                    <div
                      class="spinner-border text-secondary"
                      role="status"
                    ></div>
                  </div>
                  <canvas v-show="!pieChartLoader" id="pie-graph"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="shadow-sm rounded bg-white">
                <div v-if="sectorLoader" class="d-flex justify-content-center">
                  <div
                    class="spinner-border text-secondary"
                    role="status"
                  ></div>
                </div>
                <ul v-else class="list-group">
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center "
                  >
                    N/A
                    <span class="badge badge-primary badge-pill">{{
                      sectors[0].total
                    }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center "
                  >
                    Indigenous People (IP)
                    <span class="badge badge-primary badge-pill">{{
                      sectors[1].total
                    }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center "
                  >
                    Person with Disability (PWD)
                    <span class="badge badge-primary badge-pill">{{
                      sectors[2].total
                    }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center "
                  >
                    Senior Citizen (SC)
                    <span class="badge badge-primary badge-pill">{{
                      sectors[3].total
                    }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center "
                  >
                    Solo Parent (SP)
                    <span class="badge badge-primary badge-pill">{{
                      sectors[4].total
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
        <div class="col">
          <div class="card shadow-sm rounded">
            <div class="card-body">
              <div class="card-title">
                <strong>CAV Applications per HEI</strong>
              </div>
              <div v-if="barChartLoader" class="d-flex justify-content-center">
                <div class="spinner-border text-secondary" role="status"></div>
              </div>
              <canvas
                v-show="!barChartLoader"
                id="cav-no-per-hei"
                height="400"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineChart from './components/LineChart.vue';

export default {
  name: 'RecordsDashboard',
  components: { LineChart },
  data() {
    return {
      backgroundColors: [
        '#003f5c',
        '#124060',
        '#234263',
        '#354367',
        '#47456a',
        '#58466e',
        '#6a4871',
        '#7c4975',
        '#8d4a79',
        '#9f4c7c',
        '#b14d80',
        '#c24f83',
        '#d45087',
      ],
      displayDashboard: false,
      barChartLoader: false,
      pieChartLoader: false,
      pillLoader: false,
      sectorLoader: false,
      statuses: {
        1: { total: 0, percentage: 0 },
        2: { total: 0, percentage: 0 },
        3: { total: 0, percentage: 0 },
        4: { total: 0, percentage: 0 },
        5: { total: 0, percentage: 0 },
        6: { total: 0, percentage: 0 },
        7: { total: 0, percentage: 0 },
        8: { total: 0, percentage: 0 },
        total_apps: { total: 0, percentage: 0 },
      },
      sectors: [
        { total: 0 },
        { total: 0 },
        { total: 0 },
        { total: 0 },
        { total: 0 },
      ],
      date: moment().format('YYYY-MM-DD'),
      cavDailyAccomplishments: {
        num_payments_confirmed: '',
        num_cav_generated: '',
        for_release: '',
        num_applications_released: '',
      },
    };
  },
  methods: {
    async getApplicationsPerSector() {
      this.sectorLoader = true;
      let el = document.getElementById('cav-applications-per-sector');

      let request = await axios
        .get('api/cav-applications-per-sector')
        .then((res) => {
          console.log('getApplicationsPerSector: done');
          this.sectors = res.data;
          this.sectorLoader = false;
        });

      return request;
    },
    async getApplicationsPerStatus() {
      this.pillLoader = true;
      let request = await axios
        .get('api/cav-applications-per-status')
        .then((res) => {
          console.log('getApplicationPerStatus: done');
          this.statuses = res.data;
          this.pillLoader = false;
        });

      return request;
    },
    async initPieGraph() {
      this.pieChartLoader = true;
      let el = document.getElementById('pie-graph');

      let request = await axios
        .get('api/cav-applications-per-purpose')
        .then((res) => {
          console.log('initPieGraph: done');
          let config = {
            plugins: [ChartDataLabels],
            type: 'doughnut',
            data: {
              labels: res.data.labels,
              datasets: [
                {
                  label: 'Purpose',
                  data: res.data.data,
                  datalabels: {
                    anchor: 'center',
                    color: 'white',
                    backgroundColor: null,
                    borderWidth: 0,
                  },
                  backgroundColor: [
                    '#003f5c',
                    '#444e86',
                    '#955196',
                    '#dd5182',
                    '#ff6e54',
                    '#003f5c',
                    '#ffa600',
                  ],
                },
              ],
            },
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: 'right',
                },
                title: {
                  display: true,
                },
              },
            },
          };

          var chart = new Chart(el, config);
          this.pieChartLoader = false;
        });

      return request;
    },
    async initNumberOfCavPerHei() {
      this.barChartLoader = true;
      let c = document.getElementById('cav-no-per-hei');

      let request = await axios
        .get('api/cav-applications-per-hei')
        .then((res) => {
          console.log('initNumberOfCavPerHei: done');
          this.barChartLoader = false;
          let config = {
            plugins: [ChartDataLabels],
            type: 'bar',
            data: {
              labels: res.data.labels,
              datasets: [
                {
                  label: 'No. of CAV Applications',
                  data: res.data.total,
                  datalabels: {
                    color: 'white',
                    font: { weight: 'bold' },
                  },
                  backgroundColor: this.backgroundColors,
                },
              ],
            },
            options: {
              indexAxis: 'y',
              scales: {
                myScale: {
                  position: 'top',
                },
              },
            },
          };

          let chart = new Chart(c, config);
        });

      return request;
    },
    authCheck() {
      axios
        .get('api/check-auth')
        .then((response) => {
          if (!response.data) {
            this.$store.commit('setAuthentication', false);
            this.$store.commit('setUser', null);
            this.$router.push({ name: 'Login' });
          }
        })
        .catch((error) => {});
    },
    async initializeDashboard() {
      await this.initNumberOfCavPerHei();
      await this.initPieGraph();
      await this.getApplicationsPerStatus();
      await this.getApplicationsPerSector();
      this.displayDashboard = true;
    },

    hasIncomingDocuments() {
      axios
        .get('api/documents/incoming')
        .then((res) => {
          this.$store.commit(
            'setDocumentTrackingSidebarBadge',
            res.data.length ? true : false
          );
        })
        .catch((err) => {});
    },

    getCavDailyAccomplishments() {
      axios
        .get('api/cav-daily-accomplishments/' + this.date)
        .then((res) => {
          Object.assign(this.cavDailyAccomplishments, res.data);
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.authCheck();
    this.hasIncomingDocuments();
    this.initializeDashboard();
    this.getCavDailyAccomplishments();
  },
};
</script>
