<template>
  <div>
    <div class="card shadow-sm rounded">
      <div class="card-body">
        <div class="card-title">
          <input
            type="month"
            class="form-control form-control-sm col-2"
            v-model="selectedMonth"
            @change="updateChart"
          />
        </div>
        <div v-if="loader" class="d-flex justify-content-center">
          <div class="spinner-border text-secondary" role="status"></div>
        </div>
        <canvas
          v-show="!loader"
          id="cav-applications-canvas"
          height="50px"
        ></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: 'LineChart',
  data() {
    return {
      loader: false,
      selectedMonth: '',
      chart: '',
      data: '',
    };
  },
  methods: {
    async updateChart() {
      this.chart.destroy();
      await this.sendDataRequest();
      this.drawLineChart(this.data.dates, this.data.total);
    },
    setLineChartCurrentMonth() {
      const date = new Date();
      const month = '0' + (date.getMonth() + 1);
      this.selectedMonth = date.getFullYear() + '-' + month;
    },
    sendDataRequest() {
      this.loader = true;
      return axios
        .get('api/monthly-cav-applications/' + this.selectedMonth)
        .then((res) => {
          this.data = res.data;
          this.loader = false;
        })
        .catch((err) => {});
    },
    drawLineChart(labels, data) {
      let el = document.getElementById('cav-applications-canvas');

      let config = {
        plugins: [ChartDataLabels],
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'No. of CAV Applications',
              data: data,
              borderColor: '#321fdb',
              backgroundColor: '#321fdb',
              borderWidth: 2,
              datalabels: {
                align: 'end',
                anchor: 'end',
              },
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              borderRadius: 4,

              font: {
                weight: 'bold',
              },

              padding: 6,
            },
          },

          scales: {
            y: {
              max: 100,
            },
          },
        },

        aspectRatio: 1,
      };

      this.chart = new Chart(el, config);
    },
    async generateChart() {
      this.setLineChartCurrentMonth();
      await this.sendDataRequest();
      this.drawLineChart(this.data.dates, this.data.total);
    },
  },
  mounted() {
    this.generateChart();
  },
};
</script>
